import React, { useEffect, useRef, useState } from "react"
import { motion } from "framer-motion";
import "../Home/Modal/GenericModalButton.css"
import { useDevice } from "../../Context/DeviceContext";

export default function GenericModernModal({ toggleVisibleStateInParent, modalVisible, toggleScrollFunction, content, closingThreshold, forceClose }) {
    const [showModal, setShowModal] = useState(modalVisible);
    const modalRef = useRef(null)
    const contentRef = useRef(null)
    const [startY, setStartY] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const [scrollY, setScrollY] = useState(0);
    const [currentText, setCurrentText] = useState("Trend")
    const [closeModal, setCloseModal] = useState(false)
    const [contentHeight, setContentHeight] = useState(0);
    const backgroundOpacity = 0.67

    const {isMobile} = useDevice()
    const [mouseDown, setMouseDown]= useState(false)

    useEffect(() => {
        if (contentRef?.current?.clientHeight) {
            setContentHeight(contentRef.current.firstChild.clientHeight)
        }else{
            setContentHeight("auto")
        }
    }, [content]);

    useEffect(()=>{
        if(toggleScrollFunction) toggleScrollFunction()
    },[])

    useEffect(()=>{
        if(forceClose) toggleCloseModal()
    },[forceClose])

    

    function toggleCloseModal() {
        setCloseModal(!closeModal)
        setTimeout(() => {
            setShowModal(false);
            if (toggleVisibleStateInParent) toggleVisibleStateInParent()
            if (toggleScrollFunction) toggleScrollFunction()
        }, 300);
        setTimeout(() => {
            setCloseModal(false)
        }, 300);
    }


    const handleTouchStart = (event) => {
        if(isMobile){
            if(event._reactName==="onMouseDown") return
            setStartY(event.touches[0].clientY || event.screenY)
            setScrollY(modalRef.current.scrollTop)
        }else{
            if(event._reactName==="onTouchStart") return
            setStartY(event.clientY)
            setScrollY(modalRef.current.scrollTop)
            setMouseDown(true)
        }
    };

    const handleTouchMove = (event) => {
        if(!isMobile && !mouseDown) return

        if(isMobile){
            if(event._reactName==="onMouseMove") return
            const deltaY = event.touches[0].clientY - startY;
            const modalHeight = modalRef.current.clientHeight;
            let opacity;
    
            if (deltaY > 0 && modalRef.current.scrollTop === 0) {
                modalRef.current.style.transform = `translateY(${deltaY}px)`;
    
                opacity = Math.max(0, backgroundOpacity - Math.abs(deltaY * 2 / modalHeight));
                modalRef.current.style.backgroundColor = `rgba(1, 0, 0, ${opacity})`;
            }

        }else{
            if(mouseDown){
                if(event._reactName==="onTouchMove") return
                const deltaY = event.clientY- startY
                const modalHeight = modalRef.current.clientHeight;
                let opacity

                if (deltaY > 0 && modalRef.current.scrollTop === 0) {
                    modalRef.current.style.transform = `translateY(${deltaY}px)`;
    
                    opacity = Math.max(0, backgroundOpacity - Math.abs(deltaY * 2 / modalHeight));
                    modalRef.current.style.backgroundColor = `rgba(1, 0, 0, ${opacity})`;
                }
            }
        }
       
    };

    const handleTouchEnd = (event) => {
        let deltaY=null

        if(isMobile){
            if(event._reactName==="onMouseUp") return
            deltaY = event.changedTouches[0].clientY - startY;
        }else{
            if(event._reactName==="onTouchEnd") return
            deltaY = event.clientY - startY;
        }

        setMouseDown(false)
        
        const modalContent = modalRef.current.querySelector('.metric-modal-content');
        // const scrollPosition = modalContent.scrollTop;

        if (!modalContent.contains(event.target)) {
            toggleCloseModal()
        }

        
        if (deltaY > closingThreshold) {
            const animationStart = deltaY / modalRef.current.clientHeight * 100;
            modalRef.current.style.animation = `slideOut 0.3s ease forwards`;
            modalRef.current.style.animationDelay = `${-animationStart}ms`;
            modalRef.current.style.transform = `translateY(-${deltaY}px)`;
            setTimeout(() => {
                try{
                    modalRef.current.style.transition = ''; // Remove transition after animation
                    toggleCloseModal();
                }catch(err){
                    console.log("modalRef animation not reset")
                    toggleCloseModal();
                }
                
            }, 300);
        } else {
            // If modal doesn't close, return it to standard position
            modalRef.current.style.transition = 'transform 0.3s ease';
            modalRef.current.style.transform = 'none';
            setTimeout(() => {
                try{
                    modalRef.current.style.transition = ''; // Remove transition after animation
                }catch(err){
                    console.log("modalRef animation not reset")
                }
                
            }, 300);
        }

        modalRef.current.style.transform = '';
        modalRef.current.style.backgroundColor = `rgba(0, 0, 0, ${backgroundOpacity})`;
    };


    return (
        <div>
            {showModal && (
                <div
                    className={`metric-modal-background darker-background backgroundHeight ${closeModal ? "closeBackground" : ""} `}
                    ref={modalRef}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}

                    onMouseDown={handleTouchStart}
                    onMouseMove={handleTouchMove}
                    onMouseUp={handleTouchEnd}

                    
                    >

                    <div className={`metric-modal-content smallerContent ${closeModal ? "close" : ""}  ${isMobile ? "" : "webWidth"}`}>
                        <div className="closing-slider-container">
                            <div className='closing-slider'></div>
                        </div>

                        <motion.div
                            className="contentdiv"
                            ref={contentRef}
                            animate={{ height: contentHeight}}
                            transition={{ duration: 0.3, ease: "easeInOut" }}
                        >
                            {content}
                        </motion.div>


                    </div>

                </div>
            )
            }
        </div >
    )
}
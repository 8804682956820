import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { useUser } from '../Context/UserContext';
import { MyProvider } from '../Context/MyContext';
import FixedMenu from '../components/FixedMenu/FixedMenu';
import "../css/Transactions.css"
import {motion} from "framer-motion"
import ApiConfig from '../apiConfig';
import TransactionDataBlock from '../components/Transactions/TransactionDataBlock';
import TransactionActions from '../components/Transactions/TransactionActions';
import SuccessPopup from '../components/Home/Loading/SuccessPopup';
import CryptoLoadingAnimation from '../components/LoadingAnimation/CryptoLoadingAnimation';



const Transactions = () => {
  const baseURL = ApiConfig.baseUrl;
  const { userData: user } = useUser();
  const [transactions, setTransactions] = useState([]);
  const [refresher, setRefresher] = useState(0);
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(true)
  const [pageScrollable, setPageScrollable]= useState(true)
  const [isPopupVisible, setPopupVisible] = useState(false);
    const [popupText, setPopupText] = useState('');

  let data = JSON.stringify({
    "username": user.username,
    "password": user.password
  });

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseURL}/api/transactions/ordered`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  function toggleScroll(){
    setPageScrollable(!pageScrollable)
  }

  // function Sleep(milliseconds) {
  //   return new Promise(resolve => setTimeout(resolve, milliseconds));
  // }

  function togglePopup(text){
    setPopupText(text)
    setPopupVisible(true)
}

  useEffect(() => {
    const getData = async () => {
      axios.request(config)
        .then(res => {
          setTransactions(res.data);
          setShowLoadingAnimation(false)
        }).catch(err => {
          console.log(err);
        })
    }
    getData()
  }, [refresher]);

  useEffect(() => {
    setTimeout(() => {
        setPopupVisible(false);
    }, 2000);

}, [isPopupVisible]);


  return (
    <div className="menubarMargin" style={{ touchAction: `${!pageScrollable ? "none" : ""}` }}>
      {showLoadingAnimation ? (
        <CryptoLoadingAnimation/>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <MyProvider>
            <div className="buttonContainer prevent-scroll">
             

              <TransactionActions toggleScrollFunction={toggleScroll} refreshHandler={() => {
                  setRefresher(refresher + 1);
                }} popUpFunction={togglePopup}></TransactionActions>

            </div>

            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginBottom:"140px" }}>
              <TransactionDataBlock transactions={transactions} ></TransactionDataBlock>
            </div>

            
            <SuccessPopup isVisible={isPopupVisible} text={popupText} />
            

            
          </MyProvider>
        </motion.div>
      )}

      <FixedMenu></FixedMenu>
    </div>
  );
};

export default Transactions

import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import "./FixedMenu.css";
import HomeImage from "../../assets/FixedMenu/home.png";
import ActiveHome from "../../assets/FixedMenu/home-active.png";
import AnalyticsImage from "../../assets/FixedMenu/analytics.png";
import ActiveAnalyticsImage from "../../assets/FixedMenu/analytics-active.png" ;
import TransactionsImage from "../../assets/FixedMenu/orders.png";
import ActiveTransactionsImage from "../../assets/FixedMenu/orders-active.png";

export default function FixedMenu() {
    const[home, setHome]= useState()
    const[orders, setOrders]= useState()
    const[analytics, setAnalytics]= useState()
    const location = useLocation();

    // Check if the current location matches the NavLink's "to" prop
    useEffect(() => {
        handleActive(location.pathname)
        
    }, [location]);

    
    function handleActive(pathname){
        switch(pathname){
            case "/home":{
                setHome(true)
                setOrders(false)
                setAnalytics(false)
                break;
            }
            case "/transactions":{
                setHome(false)
                setOrders(true)
                setAnalytics(false)
                break;
            }
            case "/analytics":{
                setHome(false)
                setOrders(false)
                setAnalytics(true)
                break;
            }
            default:{}
        }

    }
    return (
        <div className="fixed-menu">
            <NavLink
                to="/transactions"
                className="menu-button"

            >
                <img src={orders? ActiveTransactionsImage:TransactionsImage} alt="Transactions" />
                <span className={`menu-label ${orders? "activeLabel": ""}`}>
                    {/* Orders */}
                </span>
            </NavLink>

            <NavLink
                to="/home"
                className="menu-button"
            >
                <img src={home? ActiveHome:HomeImage} alt="Home" />
                <span className={`menu-label ${home? "activeLabel": ""}`}>
                    {/* Home */}
                </span>
            </NavLink>

            <NavLink
                to="/analytics"
                className="menu-button"
            >
                <img src={analytics? ActiveAnalyticsImage:AnalyticsImage} alt="Analytics" />
                <span className={`menu-label ${analytics? "activeLabel": ""}`}>
                    {/* Analytics */}
                </span>
            </NavLink>
        </div>
    );
}

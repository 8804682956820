import React, { useEffect, useState } from 'react'
import LogoutButton from '../Buttons/LogoutButton'
import "./TransactionModalContent.css"
import GenericModalContentButton from '../Buttons/GenericModalContentButton'
import axios from 'axios'
import apiConfig from '../../apiConfig'

export default function TransactionModalContent({handleDelete, toggleModal, transaction, popUpFunction, refreshHandler}) {

  const baseURL= apiConfig.baseUrl
  const [editMode, setEditMode]= useState(false)
  const [currTransaction, setTransaction] = useState(transaction);



  const editConfig= ()=>{
    return {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseURL}/api/transactions/edit`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: currTransaction
    }
  }

  async function submitEdit(){
    const result =await axios.request(editConfig())
    toggleModal()
    refreshHandler()
    sessionStorage.setItem("didMount", false)
    popUpFunction(result.data)
    
  }


  function toggleEditMode(){
    setEditMode(!editMode)
  }

  function formatFloat(num) {
    let fixedNum = num.toFixed(8);
    let trimmedNum = fixedNum.replace(/(\.\d*?[1-9])0+|\.0+$/, '$1');
    return trimmedNum;
  }

  function convertPriceStringToNumber(priceString) {
    let cleanedString = priceString.replace(/[^\d,.-]/g, '');
    cleanedString = cleanedString.replace(',', '.');
    cleanedString = cleanedString.replace(/\.(?=\d{3})/g, '');
    const priceNumber = parseFloat(cleanedString);
    return priceNumber;
  }

  const handlePriceChange = (e) => {
    const updatedPrice = convertPriceStringToNumber(e.target.value)
    setTransaction((prevTransaction) => ({
      ...prevTransaction,
      price: updatedPrice
    }));
  };

  function formatUnixTime(unixTime) {
    // Create a Date object from the Unix timestamp
    const date = new Date(unixTime);

    // Extract hours and minutes
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');

    // Extract day, month, and year
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const year = date.getUTCFullYear();

    // Format the date and time into the desired format
    return `${hours}:${minutes} ・ ${day}.${month}.${year}`;
  }

  const transactionInfo= ()=>{
    return (
      <div>

        <div className='modal-entry-container' style={{marginTop:"30px"}}>
          <p className='entry-left-text'>Transaction ID</p>
          <p className='entry-right-text'>{transaction.id}</p>
        </div>

        <div className='modal-entry-container'>
          <p className='entry-left-text'>Amount</p>
          <p className='entry-right-text'>{formatFloat(transaction.amount) +" "+ transaction.ticker}</p>
        </div>

        <div className='modal-entry-container'>
          <p className='entry-left-text'>Buy Price</p>
          <p className='entry-right-text'>{(transaction.price).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</p>
        </div>

        <div className='modal-entry-container'>
          <p className='entry-left-text'>Date</p>
          <p className='entry-right-text'>{formatUnixTime(transaction.unixTime)}</p>
        </div>

        <div className='modal-entry-container'>
          <p className='entry-left-text'>Unix Time</p>
          <p className='entry-right-text'>{transaction.unixTime}</p>
        </div>

        <div className='modal-entry-container'>
          <p className='entry-right-text'>Total</p>
          <p className='entry-right-text'>{(transaction.amount*transaction.price).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</p>
        </div>
      </div>

    )
  }

  const transactionsEditMode= ()=>{
    return (
      <div>
        <div className='modal-entry-container' style={{marginTop:"30px"}}>
          <p className='entry-left-text'>Transaction ID</p>
          <p className='entry-right-text'>{transaction.id}</p>
        </div>

        <div className='modal-entry-container'>
          <p className='entry-left-text'>Amount</p>
          <p className='entry-right-text'>{formatFloat(transaction.amount) +" "+ transaction.ticker}</p>
        </div>

        <div className='modal-entry-container'>
          <p className='entry-left-text'>Buy Price</p>
          <div className='entry-right-text'>
            <input onChange={handlePriceChange} className='modal-edit-input' defaultValue={(transaction.price).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}></input>
          </div>
        </div>

        <div className='modal-entry-container'>
          <p className='entry-left-text'>Date</p>
          <p className='entry-right-text'>{formatUnixTime(transaction.unixTime)}</p>
        </div>

        <div className='modal-entry-container'>
          <p className='entry-left-text'>Unix Time</p>
          <p className='entry-right-text'>{transaction.unixTime}</p>
        </div>

        <div className='modal-entry-container'>
          <p className='entry-right-text'>Total</p>
          <p className='entry-right-text'>{(transaction.amount*transaction.price).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</p>
        </div>
      </div>
    )
  }


  return (
    <div style={{marginTop:"30px"}}>

      <div className='top-modal-container'>
        <h1 className='top-text'>Detail Transaction</h1>

        <span className="material-symbols-outlined edit-img" onClick={toggleEditMode}>
          edit_square
        </span>

      </div>

      <div className='middle-modal-container'>
        {editMode ? transactionsEditMode(): transactionInfo()}
        

      </div>
      
      <div className='bottom-modal-container' style={{marginTop:"30px"}}>
        <GenericModalContentButton buttonText={"Close"} iconName={"close"} customBackground={"#353535"} customWidth={"38%"} handleFunction={toggleModal} iconColor={"black"}></GenericModalContentButton>
        {editMode ? <GenericModalContentButton buttonText={"Save"} iconName={"upload_file"} customBackground={"green"} customWidth={"38%"} handleFunction={submitEdit} iconColor={"white"}></GenericModalContentButton> : <LogoutButton customText={"Delete"} customLogoName={"delete"} handleFunction={handleDelete} customWidth={"38%"}></LogoutButton>}
      </div>
        
        <div style={{height:"20px"}}></div>
    </div>
  )
}

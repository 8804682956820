import React, { useState, useRef, useEffect } from 'react';
import './GenericModalButton.css';
import { useDevice } from '../../../Context/DeviceContext';

export default function GenericModalButton({ mainText, modalMainText, currentMetric, handleChangeInParent, toggleScrollFunction, closingThreshold }) {
    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef(null);
    const [startY, setStartY] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const [scrollY, setScrollY] = useState(0);
    const [currentlyActive, setCurrentlyActive] = useState(currentMetric)
    const [currentText, setCurrentText] = useState("Trend")
    const [closeModal, setCloseModal] = useState(false)
    const backgroundOpacity=0.67

    const {isMobile} = useDevice()
    const [mouseDown, setMouseDown]= useState(false)
    
    const toggleModal = () => {
        if (toggleScrollFunction) toggleScrollFunction()
        setShowModal(!showModal)
    };

    function toggleCloseModal() {
        setCloseModal(!closeModal)
        setTimeout(() => {
            setShowModal(false);
            if (toggleScrollFunction) toggleScrollFunction()
        }, 300);
        setTimeout(() => {
            setCloseModal(false)
        }, 300);
    }


    const handleTouchStart = (event) => {
        if(isMobile){
            if(event._reactName==="onMouseDown") return
            setStartY(event.touches[0].clientY)
            setScrollY(modalRef.current.scrollTop)
        }else{
            if(event._reactName==="onTouchStart") return
            setStartY(event.clientY)
            setScrollY(modalRef.current.scrollTop)
            setMouseDown(true)
        }
    };

    const handleTouchMove = (event) => {
        if(!isMobile && !mouseDown) return

        if(isMobile){
            if(event._reactName==="onMouseMove") return
            const deltaY = event.touches[0].clientY - startY;
            const modalHeight = modalRef.current.clientHeight;
            let opacity;
    
            if (deltaY > 0 && modalRef.current.scrollTop === 0) {
                modalRef.current.style.transform = `translateY(${deltaY}px)`;
    
                opacity = Math.max(0, backgroundOpacity - Math.abs(deltaY * 2 / modalHeight));
                modalRef.current.style.backgroundColor = `rgba(1, 0, 0, ${opacity})`;
            }

        }else{
            if(mouseDown){
                if(event._reactName==="onTouchMove") return
                const deltaY = event.clientY- startY
                const modalHeight = modalRef.current.clientHeight;
                let opacity

                if (deltaY > 0 && modalRef.current.scrollTop === 0) {
                    modalRef.current.style.transform = `translateY(${deltaY}px)`;
    
                    opacity = Math.max(0, backgroundOpacity - Math.abs(deltaY * 2 / modalHeight));
                    modalRef.current.style.backgroundColor = `rgba(1, 0, 0, ${opacity})`;
                }
            }
        }
       
    };

    const handleTouchEnd = (event) => {
        let deltaY=null

        if(isMobile){
            if(event._reactName==="onMouseUp") return
            deltaY = event.changedTouches[0].clientY - startY;
        }else{
            if(event._reactName==="onTouchEnd") return
            deltaY = event.clientY - startY;
        }

        setMouseDown(false)
        
        const modalContent = modalRef.current.querySelector('.metric-modal-content');
        // const scrollPosition = modalContent.scrollTop;

        if (!modalContent.contains(event.target)) {
            toggleCloseModal()
        }

        
        if (deltaY > closingThreshold) {
            const animationStart = deltaY / modalRef.current.clientHeight * 100;
            modalRef.current.style.animation = `slideOut 0.3s ease forwards`;
            modalRef.current.style.animationDelay = `${-animationStart}ms`;
            modalRef.current.style.transform = `translateY(-${deltaY}px)`;
            setTimeout(() => {
                try{
                    modalRef.current.style.transition = ''; // Remove transition after animation
                    toggleCloseModal();
                }catch(err){
                    console.log("modalRef animation not reset")
                    toggleCloseModal();
                }
                
            }, 300);
        } else {
            // If modal doesn't close, return it to standard position
            modalRef.current.style.transition = 'transform 0.3s ease';
            modalRef.current.style.transform = 'none';
            setTimeout(() => {
                try{
                    modalRef.current.style.transition = ''; // Remove transition after animation
                }catch(err){
                    console.log("modalRef animation not reset")
                }
                
            }, 300);
        }

        modalRef.current.style.transform = '';
        modalRef.current.style.backgroundColor = `rgba(0, 0, 0, ${backgroundOpacity})`;
    };




    function handleButtonClick(currentlyActive, currentText) {
        if (handleChangeInParent) handleChangeInParent(currentlyActive)
        setCurrentlyActive(currentlyActive)
        setCurrentText(currentText)
        toggleCloseModal()
    }


    return (
        <div className='horizontal-container'>
            <h5 className='mainText'>{mainText}</h5>
            <div className='button-container' onClick={toggleModal}>
                <h6 className='metric-text margin'>{currentText}</h6>
                <span className="material-symbols-outlined margin">expand_more</span>
            </div>
            {showModal && (
                <div
                    className={`metric-modal-background darker-background backgroundHeight ${closeModal? "closeBackground": ""} `}
                    ref={modalRef}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}

                    onMouseDown={handleTouchStart}
                    onMouseMove={handleTouchMove}
                    onMouseUp={handleTouchEnd}
                >
                    <div className={`metric-modal-content smallerContent ${closeModal ? "close" : ""} ${isMobile ? "" : "webWidth"} `}>
                        <div className="closing-slider-container">
                            <div className='closing-slider'></div>
                        </div>
                        <h2 style={{ marginTop: "30px"}} className='mainText'>{"Metric"}</h2>

                        <div className='metric-option-container noTopMargin' onClick={() => handleButtonClick("seitKaufRel", "Since Purchase")}>
                            <div className='metric-option-left-side'>
                                <span style={{ color: "white", height: "10px", width: "10px" }} className="material-symbols-outlined smallerFont">
                                    percent
                                </span>
                                <h6 style={{ color: "white", fontWeight: "bold" }}>Since Purchase</h6>
                            </div>

                            {currentlyActive === "seitKaufRel" ? <span className="material-symbols-outlined checkIconMargin">done</span> : <div></div>}
                        </div>

                        <div className='metric-option-container' onClick={() => handleButtonClick("seitKaufAbs", "Since Purchase")}>
                            <div className='metric-option-left-side'>
                                <span style={{ color: "white", height: "10px", width: "10px" }} className="material-symbols-outlined smallerFont">
                                    euro
                                </span>
                                <h6 style={{ color: "white", fontWeight: "bold" }}>Since Purchase</h6>
                            </div>

                            {currentlyActive === "seitKaufAbs" ? <span className="material-symbols-outlined">done</span> : <div></div>}
                        </div>

                        <div className='metric-option-container' onClick={() => handleButtonClick("TrendRel", "Trend")}>
                            <div className='metric-option-left-side'>
                                <span style={{ color: "white", height: "10px", width: "10px" }} className="material-symbols-outlined smallerFont">
                                    percent
                                </span>
                                <h6 style={{ color: "white", fontWeight: "bold" }}>Trend</h6>
                            </div>

                            {currentlyActive === "TrendRel" ? <span className="material-symbols-outlined">done</span> : <div></div>}
                        </div>

                        <div className='metric-option-container' onClick={() => handleButtonClick("TrendAbs", "Trend")}>
                            <div className='metric-option-left-side'>
                                <span style={{ color: "white", height: "10px", width: "10px" }} className="material-symbols-outlined smallerFont">
                                    euro
                                </span>
                                <h6 style={{ color: "white", fontWeight: "bold" }}>Trend</h6>
                            </div>

                            {currentlyActive === "TrendAbs" ? <span className="material-symbols-outlined">done</span> : <div></div>}
                        </div>


                    </div>
                </div>
            )}
        </div>
    );
}

import React, { useState } from 'react'
import AdlerLoginFormV1 from "../components/Login/AdlerLoginFormV1"
import CryptoLoadingAnimation from "../components/LoadingAnimation/CryptoLoadingAnimation";

export default function Login() {
  const [showLogin, setShowLogin] = useState(true);


  return (
    <div>
      {
        showLogin ?
        (<AdlerLoginFormV1 showLogin={setShowLogin}></AdlerLoginFormV1>)
      : 
      <div style={{marginTop:"170px", width:"100%"}}>
        <CryptoLoadingAnimation/>
      </div>
      }

    </div>




  )
}



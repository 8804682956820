import React, { useEffect, useState } from 'react';
import checkAnimation from "../../assets/ActionButtons/checkAnimation.json";
import Lottie from 'lottie-react';

export default function LoadingAnimationButton({ percentage }) {
    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
        if (percentage === 100) {
            setIsComplete(true);
        }else{
            setIsComplete(false)
        }
        
    }, [percentage]);

    return (
        <div style={{ position: 'relative', width: '40px', height: '60px' }}>
            {!isComplete ? (
                <>
                    <svg
                        width="40"
                        height="40"
                        viewBox="0 0 100 100"
                        style={{ position: 'absolute', top: 0, left: 0 }}
                    >
                        <circle
                            cx="50"
                            cy="50"
                            r="45"
                            stroke="#ddd"
                            strokeWidth="10"
                            fill="none"
                        />
                        <circle
                            cx="50"
                            cy="50"
                            r="45"
                            stroke="#23B26D"
                            strokeWidth="10"
                            fill="none"
                            strokeDasharray="283"
                            strokeDashoffset={283 - (283 * percentage) / 100}
                            transform="rotate(-90 50 50)"
                            style={{ transition: 'stroke-dashoffset 0.5s ease' }}
                        />
                    </svg>
                    <div style={{
                        position: 'absolute',
                        bottom: '9px',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '10px',
                        fontWeight: 'bold',
                        color: 'white',
                    }}>
                        {`${percentage}%`}
                    </div>
                </>
            ) : (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '285%',
                    marginLeft: "-35px",
                    marginTop: "-36px"
                }}>
                    <Lottie animationData={checkAnimation} loop={false} />
                </div>
            )}
        </div>
    );
}

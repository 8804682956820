import React, { createContext, useState } from 'react';

export const MyContext = createContext();

export const MyProvider = ({ children }) => {
  const [checkedTransactions, setCheckedTransactions] = useState([]);

  const resetCheckBoxArray=()=>{
    setCheckedTransactions([])
  }

  const handleCheckboxChange = (transaction) => {
    setCheckedTransactions((prevCheckedTransactions) => {
      if (prevCheckedTransactions.includes(transaction.id)) {
        return prevCheckedTransactions.filter((item) => item.id !== transaction.id);
      } else {
        return [...prevCheckedTransactions, transaction];
      }
    });
  };

  

  const contextValue = {
    checkedTransactions,
    handleCheckboxChange,
    resetCheckBoxArray
  };


  return (
    <MyContext.Provider value={contextValue}>
      {children}
    </MyContext.Provider>
  );
};



import React, { useState, useEffect, useContext } from "react";
import "./TransactionDataBlock.css";
import axios from "axios";
import apiConfig from "../../apiConfig";
import { MyContext } from "../../Context/MyContext";


export default function TransactionDataBlock({ transactions }) {
  const baseURL = apiConfig.baseUrl;
  const green = "#83FF9E";
  const red = "#ff3030";

  const revTransactions = [...transactions].reverse();
  const { checkedTransactions, handleCheckboxChange, resetCheckBoxArray } = useContext(MyContext);

  const [logos, setLogos] = useState({});
  let showDateDiv= false

  async function tickerToName(ticker) {
    const response = await axios.get(
      `${baseURL}/api/lib/entry/${ticker.toLowerCase()}`
    );
    return response.data;
  }

  // Function to fetch the logo URL for an asset
  async function fetchLogo(ticker) {
    try {
      const libData = await tickerToName(ticker);
      return libData.logo;
    } catch (err) {
      console.error(`Error fetching logo for ${ticker}:`, err);
      return null;
    }
  }

  function convertDateToMonthYear(dateString) {
    const [day, month, year] = dateString.split(".");
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const monthIndex = parseInt(month, 10) - 1;
    const monthName = monthNames[monthIndex];
    return `${monthName} ${year}`;
  }

  function formatFloat(num) {
    // Use toFixed(4) to ensure four decimal places initially
    let fixedNum = num.toFixed(4);
    // Use a regular expression to remove trailing zeros after the decimal point
    // Also removes the decimal point if it's not needed
    let trimmedNum = fixedNum.replace(/(\.\d*?[1-9])0+|\.0+$/, '$1');
    return trimmedNum;
  }

  

  useEffect(() => {
    // Fetch logos for each transaction's ticker
    const fetchAllLogos = async () => {
      const logosMap = {};
      for (const transaction of revTransactions) {
        const ticker = transaction.ticker;
        if (!logosMap[ticker]) {
          const logo = await fetchLogo(ticker);
          logosMap[ticker] = logo; // Use default logo if fetching fails
        }
      }
      setLogos(logosMap);
    };

    fetchAllLogos();
  }, []);

  const render=()=>{
    let lastMonth= ""

    return(
    <>
        <h1 className="transaction-title">All Transactions</h1>

        {revTransactions.map((transaction, i) =>{
        let currlastMonth= (transaction.date).substring(3,5)
        if(lastMonth!=currlastMonth){
            lastMonth= currlastMonth
            showDateDiv= true
        }else{
            showDateDiv= false
        }
        return (
        <React.Fragment key={i}>
            {showDateDiv ? <p className="month-divider">{convertDateToMonthYear(transaction.date)}</p>: <></>}
            <div className="mainContainer" key={i} >
                <div className="layer1">
                    <div className="left">
                    <div className="left-layer2">
                        <img
                        style={{ height: "30px", width: "30px", marginLeft: "10px", marginTop:"4px" }}
                        src={logos[transaction.ticker]}
                        alt={transaction.ticker}
                        />
                        <div className="left-block">
                        <p style={{ marginBottom: "2px" }}>{transaction.ticker}</p>
                        <p
                            className="amount-text"
                            style={{ color: transaction.amount > 0 ? green : red }}
                        >
                            {(transaction.amount > 0 ? "+" : "") +
                            formatFloat(transaction.amount)}
                        </p>
                        </div>
                    </div>
                    </div>
                    <div className="middle">
                    <p className="text">{transaction.date}</p>
                    </div>
                    <div className="right">
                    <div className="right-block">
                        <p className="text value-text">
                        {(transaction.amount * transaction.price).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
                        </p>
                        <span onClick={()=>handleCheckboxChange(transaction)} className="material-symbols-outlined">chevron_right</span>
                    </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
      )})}
      </>)

  }

  return render()
}

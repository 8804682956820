import React, { createContext, useContext, useState, useEffect } from "react";

const ProgressContext = createContext();

export function ProgressProvider({ children }) {
  const [sessionKey, setSessionKey] = useState();
  const [progressData, setProgressData] = useState();

  function Sleep(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }

  useEffect(() => {
    let ws;

    const connectToWebSocket = async () => {
      let reconnect = false;
      ws = new WebSocket(`wss:adlerbackend-adleranalytics.koyeb.app/progress`);

      ws.onmessage = (message) => {
        const data = message.data;

        if (data.startsWith("SESSION_ID:")) {
          let sessionId = data.substring("SESSION_ID:".length);
          setSessionKey(sessionId);
          console.log("Received session ID:", sessionId);
        } else {
          const jsonData = JSON.parse(data);
          setProgressData(jsonData);
        }
      };

      ws.onopen = () => {
        console.log("Progress Websocket connected");
      };

      ws.onclose = async () => {
        if (!reconnect) {
          console.log("Progress Websocket disconnected | Reconnecting in 30s");
          await Sleep(10000);
          connectToWebSocket();
        }
      };
    };

    connectToWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  return (
    <ProgressContext.Provider value={{ sessionKey, progressData }}>
      {children}
    </ProgressContext.Provider>
  );
}

export function useProgress() {
  return useContext(ProgressContext);
}

import React, { useEffect, useState } from 'react'
import "./AnalyticsDataBlock.css"

export default function AnalyticsDataBlock({ title, img, value, text, color, lengthy, expand, modalfunction }) {
  const [size, setSize] = useState("")
  /*  img = euro
   title = "Nominal Value"
   value = "20.000.23€" */


  useEffect(() => {
    if (lengthy) {
      setSize("long")
    }
  }, [])



  return (
    <div className={`block-container`}>
      <div className='data-top-row'>
        <div style={{display:"flex", flexDirection:"row"}}>
          <img className='logo' src={img}></img>
          <div className={`asset-title`}>{title}</div>
        </div>

        {expand ? (<span onClick={()=>{if(modalfunction) modalfunction()}} style={{marginTop:"6px"}} className="material-symbols-outlined">expand_more</span>):(<p></p>)}
      </div>

      <div style={{ color: color }} className={`asset-value-analytics`}>{value}</div>
    </div>
  )
}

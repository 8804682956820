import React, { useState, useEffect } from 'react';
import "./Popup.css"

const SuccessPopup = ({ isVisible, text, color, customWidth }) => {

    useEffect(() => {
        if (isVisible) {
          setTimeout(() => {
          }, 1500);
        }
      }, [isVisible]);
    
      return (
        <div className={`success-popup ${isVisible ? 'visible' : ''} ${color}` } style={{width:`${customWidth ? customWidth : ""}`}}>
          <p>{text}</p>
        </div>
      );
    };

export default SuccessPopup;

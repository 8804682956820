import axios from 'axios';
import React, { PureComponent, useEffect, useState, useRef, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { useUser } from '../../../Context/UserContext';
import "./LineChartReV2.css"
import Up from '../../../assets/Arrows/triangle-up.png'
import Down from '../../../assets/Arrows/triangle-down.png'
import {motion } from "framer-motion"
import ApiConfig from '../../../apiConfig';
import { useChartTouched } from '../../../Context/ChartScrollContext';
import CryptoLoadingAnimation from '../../LoadingAnimation/CryptoLoadingAnimation';

export default function LineChartReV2({ ticker, chartHeight }) {
    const [showLoadingAnimation, setShowLoadingAnimation] = useState(true)
    const baseURL = ApiConfig.baseUrl;
    const apiKeys = ["CG-RC9nSmEBYzvHCMVfNAd81eN5", "CG-SdaQvVp7TH1Y2KJ85MfDaxLu", "CG-CPFogeuCJ78aFoA3KvpPSVsS", "CG-iFGMTVLJdxhTU5a8YSjm3Ctb"]
    const apiKEyR = "CG-dR2TB1mD6cjvR7MKBjGAGENH"
    const { userData: user } = useUser();
    let userData = JSON.stringify({
        "username": user.username,
        "password": user.password
    });
    const chartPositiveColor = "#2dd649"
    const chartNegativeColor = "#ff3030"

    const [toolTipDate, setTooltipDate] = useState(formatDate(new Date(), 'dd.MM.yyyy HH:mm'));
    const [tooltipPrice, setTooltipPrice] = useState(0)
    const [showCursor, setShowCursor] = useState(false);
    const [animation, setAnimation] = useState(true)
    const [timeframe, setTimeframe] = useState(1);
    const [dynamicPnLAbs, setDynamicPnLAbs] = useState()
    const [dynamicPnLRel, setDynamicPnLRel] = useState()
    const [dynamicPnLColor, setDynPnLColor] = useState()
    const [chartLineColor, setChartLineColor] = useState()
    const [pageScrollable, setPageScrollable] = useState(true)
    const [combinedData, setCombinedData] = useState([])
    const [lastPrice, setLastPrice] = useState()
    const rawLastPrice = useRef()
    const combinedDataRef = useRef()
    const { setTouched } = useChartTouched()


    const componentStyles = {
        backgroundColor: '#0d0d0e',
        color: '#FFFFFF',
        marginTop: "20px"
    };

    let assetPricesConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseURL}/api/crypto/assetPrices`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: userData
    };

    const handleMouseMove = (event) => {
        const chartRef = document.querySelector('.Chart-Container .recharts-wrapper');
        if (!chartRef) {
            return;
        }
        const dataIndex = event.activeTooltipIndex
        if (dataIndex >= 0 && dataIndex < combinedDataRef.current.length) {
            const price = combinedDataRef.current[dataIndex].price;
            setTooltipPrice(price);
            setLastPrice(price);
            setTooltipDate(combinedDataRef.current[dataIndex].date);

            const chartData = combinedDataRef.current
            let referencePrice = chartData[0].price
            let currPrice = price
            let totalPnL = currPrice - referencePrice
            let relativePnL = (currPrice / referencePrice - 1) * 100
            if (totalPnL > 0) { setDynPnLColor("positive"); } else { setDynPnLColor("negative") }
            setDynamicPnLAbs(totalPnL.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }))
            setDynamicPnLRel(relativePnL.toFixed(2))
        }
    };

    const resetDynamicPnLData = () => {
        try {
            const chartData = combinedDataRef.current
            let referencePrice = chartData[0].price
            const currPrice = rawLastPrice.current
            let totalPnL = currPrice - referencePrice
            let relativePnL = (currPrice / referencePrice - 1) * 100
            if (totalPnL > 0) { setDynPnLColor("positive"); setChartLineColor(chartPositiveColor) } else { setDynPnLColor("negative"); setChartLineColor(chartNegativeColor) }
            setDynamicPnLAbs(totalPnL.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }))
            setDynamicPnLRel(relativePnL.toFixed(2))
            localStorage.setItem("absPnL", totalPnL.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }))
            localStorage.setItem("relPnL", relativePnL.toFixed(2))
            setTooltipDate(formatDate(new Date(), 'dd.MM.yyyy HH:mm'));
            setShowLoadingAnimation(false)

        } catch (err) {
            console.log(err)
        }
    }


    function formatDate(timestamp) {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const monthNames = ['Jan.', 'Feb.', 'März.', 'Apr.', 'Mai', 'Juni', 'Juli', 'Aug.', 'Sept.', 'Oct.', 'Nov.', 'Dez.'];
        const month = monthNames[date.getMonth()];
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}. ${month} · ${hours}:${minutes}`;
    }

    let currentKeyIndex = 0;
    function getNextApiKey() {
        const key = apiKeys[currentKeyIndex];
        currentKeyIndex = (currentKeyIndex + 1) % apiKeys.length;
        return key;
    }

    async function fetchMarketData(tickerName, timeFrame) {
        const apiKey = getNextApiKey();
        try {
            const response = await axios.get(`https://api.coingecko.com/api/v3/coins/${tickerName}/market_chart?vs_currency=eur&days=${timeFrame}&precision=2&x_cg_demo_api_key=${apiKey}`, { timeout: 3000 });
            return response.data;
        } catch (error) {
            console.error(`Error fetching data for ${tickerName} with key ${apiKey}`);
            throw error; // Rethrowing the error for retry logic if needed
        }
    }

    async function tickerToName(ticker) {
        const response = await axios.get(`${baseURL}/api/lib/${ticker}`)
        return response.data
    }

    async function calcChart(timeFrame) {
        const pricesResponse = await axios.request(assetPricesConfig)
        const cryptoPrice = pricesResponse.data.data[ticker][0].quote.EUR.price;
        setLastPrice(cryptoPrice)
        rawLastPrice.current = cryptoPrice

        const tickerName = await tickerToName(ticker.toLowerCase());

        const marketData = await fetchMarketData(tickerName, timeFrame);

        const formattedData = marketData.prices.map(([timestamp, price]) => ({
            timestamp,
            price: price,
            date: formatDate(timestamp)
        }));

        setCombinedData(formattedData)
        combinedDataRef.current = formattedData

    }


    useEffect(() => {
        async function load() {
            await calcChart(timeframe)
            resetDynamicPnLData()
        }

        load()
    }, [timeframe])

    return (
        <div style={{ touchAction: `${!pageScrollable ? "none" : ""}` }}>
            {!showLoadingAnimation ? (
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, type: 'spring', damping: 15, stiffness: 100 }}
                >
                    <div style={componentStyles}>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "-30px" }}>
                            {lastPrice ? (

                                <div>
                                    <h1 className={`account-value`}>
                                        {lastPrice.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
                                    </h1>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <img src={dynamicPnLColor == "negative" ? Down : Up} className='UpDownPic' />
                                        <p className={`tooltip-pnl ${dynamicPnLColor} account-pnl-values`}>
                                            {dynamicPnLAbs.replace("-", "")} ( {dynamicPnLRel.replace("-", "")}%)
                                        </p>
                                    </div>
                                    <p className="tooltip-date account-pnl-values">{toolTipDate}</p>

                                </div>
                            ) : (
                                <div class="text-center">
                                    <div class="spinner-border spinner-border-sm" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            )}
                        </div>


                        {combinedData.length > 0 ? (
                            <div>
                                <div className='Chart-Container marginGetter' style={{ touchAction: "none" }}>
                                    <ResponsiveContainer width="100%" height={chartHeight} >
                                        <LineChart width={300} height={100} data={combinedData} margin={{ right: 10, left: 5 }} onMouseMove={(event) => handleMouseMove(event)} onMouseUp={async () => { setTouched(false); setShowCursor(false); setLastPrice(rawLastPrice.current); resetDynamicPnLData() }} onMouseDown={() => { setTouched(true); setShowCursor(true) }} >
                                            <XAxis dataKey="date" hide />
                                            <YAxis scale="auto" domain={['auto', 'auto']} hide />
                                            <Tooltip
                                                contentStyle={{
                                                    display: "none",
                                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                                    border: '1px solid #ccc',
                                                    padding: '5px',
                                                    borderRadius: '5px',
                                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                }}
                                                labelStyle={{
                                                    marginBottom: '2px',
                                                    fontWeight: 'bold',
                                                    fontSize: '12px',
                                                }}
                                                itemStyle={{
                                                    color: '#888',
                                                    fontSize: '12px',
                                                }}
                                                labelFormatter={label => `Date: ${label}`}
                                                formatter={(value, name) => [`Price: ${value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}`]}
                                                position={{ y: -10 }}
                                                cursor={showCursor ? "crosshair" : false}

                                            />

                                            <Line animationDuration={1000} stroke={chartLineColor} isAnimationActive={animation} type="monotone" dataKey="price" dot={false} strokeWidth={2} activeDot={false} />
                                            <ReferenceLine strokeWidth={.5} y={combinedData[0].price} stroke="white" strokeDasharray="3 4.2" />


                                        </LineChart>
                                    </ResponsiveContainer>

                                </div>

                                <div className='d-grid gap-2 d-md-block' style={{ display: 'flex', /*justifyContent: 'center' ,*/ alignItems: 'center', marginBottom: '20px' }}>
                                    <div className="btn-group" role="group1" aria-label="Basic radio toggle button group">
                                        <input type="radio" className="btn-check" name="btnradio2" id="btnradio100" autoComplete="off" defaultChecked />
                                        <label onClick={() => { setTimeframe(1) }} className="btn btn-outline-primary1 modalButtonOutline" htmlFor="btnradio100" >1D</label>

                                        <input type="radio" className="btn-check" name="btnradio2" id="btnradio101" autoComplete="off" />
                                        <label onClick={() => { setTimeframe(7) }} className="btn btn-outline-primary1 modalButtonOutline" htmlFor="btnradio101" >1W</label>

                                        <input type="radio" className="btn-check" name="btnradio2" id="btnradio102" autoComplete="off" />
                                        <label onClick={() => { setTimeframe(31) }} className="btn btn-outline-primary1 modalButtonOutline" htmlFor="btnradio102" >1M</label>

                                        <input type="radio" className="btn-check" name="btnradio2" id="btnradio103" autoComplete="off" />
                                        <label onClick={() => { setTimeframe(365) }} className="btn btn-outline-primary1 modalButtonOutline" htmlFor="btnradio103" >1Y</label>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className="spinner-grow" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>)}
                    </div>
                </motion.div>) : (
                <div>
                    <CryptoLoadingAnimation/>
                </div>
            )
            }
        </div >
    )
}

import { createContext, useContext, useState } from "react";

const ChartTouchContext = createContext();

export function ChartTouchProvider({ children }) {
  const [touched, setTouched] = useState(false);

  return (
    <ChartTouchContext.Provider value={{ touched, setTouched }}>
      {children}
    </ChartTouchContext.Provider>
  );
}

export function useChartTouched() {
  return useContext(ChartTouchContext);
}

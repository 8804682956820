import React from 'react'
import './CryptoAddButtonV2.css'

function CryptoAddButtonV2({handleFunction}) {
    return (
        <div onClick={()=>handleFunction()} className='option-container'>
            <span className="material-symbols-outlined">
                add_circle
            </span>
            <p className='text'>Add Currency</p>
        </div>
    )
}

export default CryptoAddButtonV2

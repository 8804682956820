import React, { useEffect, useState } from 'react';
import { VictoryPie, VictoryAnimation, VictoryLabel, VictoryLegend } from 'victory';


export default function GoalCircle({ percentage }) {
    const [circleData, setCircleData] = useState({
        percent: percentage,
        circle: getCircleData(percentage),
    });

    useEffect(() => {
        const timeout = setTimeout(() => {
            setCircleData({
                percent: percentage,
                circle: getCircleData(percentage),
            });
        }, 1100);

        return () => {
            clearTimeout(timeout);
        };

    }, [percentage]);

    function getCircleData(percent) {
        return [
            { x: 1, y: percent },
            { x: 2, y: 100 - percent },
        ];
    }

    return (
        <div>
            <svg viewBox="16 18 400 400" width="110%" height="110%">
                <VictoryPie
                    standalone={false}
                    animate={{ duration: 1000 }}
                    width={400}
                    height={400}
                    data={circleData.circle} // Use circleData instead of data
                    innerRadius={120}
                    cornerRadius={25}
                    labels={() => null}
                    style={{
                        data: {
                            fill: ({ datum }) => {
                                const color = datum.y > 0 ? '#2bff00' : 'red';
                                return datum.x === 1 ? color : 'transparent';
                            },
                        },
                    }}


                />

                <VictoryAnimation duration={1000} data={circleData}>
                    {(newProps) => (
                        <VictoryLabel
                            textAnchor="middle"
                            verticalAnchor="middle"
                            x={200}
                            y={200}
                            text={`${Math.round(newProps.percent)}%`}
                            style={{ fontSize: 45, fill: "white" }}
                        />
                    )}
                </VictoryAnimation>

            </svg>
        </div>
    );
}

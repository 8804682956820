import React, { useEffect, useState } from "react";
import "../css/TestPage.css";
import CryptoLoadingAnimation from "../components/LoadingAnimation/CryptoLoadingAnimation";
import useLidoApiFetch from "../Hooks/useLidoApiFetch";
import { useDevice } from "../Context/DeviceContext";
import AssetContainerWeb from "../components/Home/WebVersion/AssetContainerWeb";
import HomeLoading from "../components/Home/Loading/HomeLoading";

export default function TestPage() {
  const [showModal, setShowMoadal] = useState(false);
  const [showAssetModal, setShowAssetModal] = useState(true);
  const [chartIsTouched, setChartIsTouched] = useState(false);
  const [timeframe, setTimeframe] = useState(1);

  const {isMobile}= useDevice()

  function toggleModal() {
    setShowMoadal(!showModal);
  }

  const data1 = JSON.parse(localStorage.getItem("chartDataT1"));

  function dateToUnixTime(dateString) {
    const [date, time] = dateString.split(", ");
    const [day, month, year] = date.split(".");
    const [hours, minutes] = time.split(":");

    const unixTime = new Date(
      `${year}-${month}-${day}T${hours}:${minutes}:00`
    ).getTime();

    return unixTime;
  }

  function Sleep(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }

  return (
    <div /* className='main-container' style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }} */>
    </div>
  );
}

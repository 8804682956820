import React, { useEffect, useState } from 'react';
import './AssetContainer.css';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../../Context/ThemeContext';
import axios from 'axios';
import apiConfig from '../../../apiConfig';
import upImg from '../../../assets/Arrows/triangle-up.png'
import downImg from '../../../assets/Arrows/triangle-down.png'

export default function AssetsContainer({ assetName, amountOwned, assetValue, assetPerformance, convertToCurrency, parentClickHandle, parentOuterClickHandle}) {
  const baseURL = apiConfig.baseUrl
  const [performanceColor, setPerformanceColor] = useState()
  const [positivePerformance, setPositivePerformance]= useState()
  const [logo, setLogo] = useState()
  const formattedValue = assetValue.toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR'
  });


  const { isDarkTheme } = useTheme();

  const componentStyles = {
    backgroundColor: isDarkTheme ? '#000000' : '#FFFFFF',
    color: isDarkTheme ? '#FFFFFF' : '#000000',
  };



  async function tickerToName(ticker) {
    const response = await axios.get(`${baseURL}/api/lib/entry/${ticker.toLowerCase()}`)
    return response.data
  }

  async function fetchLogo() {
    try {
      const libData = await tickerToName(assetName)
      setLogo(libData.logo)
    } catch (err) { }
  }

  useEffect(() => {
    switch (assetPerformance > 0) {
      case true: { setPerformanceColor("positive"); setPositivePerformance(true); break; }
      case false: { setPerformanceColor("negative"); setPositivePerformance(false); break; }
    }
  }, [assetPerformance])

  useEffect(() => {
    fetchLogo();
  }, []);

  const navigate = useNavigate();
  const ticker = assetName.toLowerCase()


  return (
    <div style={componentStyles} className='asset-data-container'>
      <div className="assets-container">

        <img onClick={()=>parentOuterClickHandle(assetName)} src={logo} alt={`${assetName} Logo`} className="asset-logo" />

        <div className="asset-info" onClick={()=>parentOuterClickHandle(assetName)}>
          <div className="asset-name" >{assetName}</div>
          <div className="amount-owned" >{amountOwned} </div>
        </div>
        
        <div className="performance-info">
          <div onClick={()=>parentClickHandle()} className={`tframePerformance ${performanceColor} performanceAlignment`} >
            <img className='performanceImage' src={positivePerformance ? upImg : downImg}></img>
            {convertToCurrency ? assetPerformance.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) : assetPerformance + " %"}
          </div>
        </div>
      </div>
    </div>
  );


}


import React from 'react'
import './CryptoAddButtonV2.css'

export default function ReloadT1Button({handleFunction}) {
  return (
    <div onClick={()=>handleFunction()} className='option-container' style={{marginTop:"12px"}}>
            <span className="material-symbols-outlined">
                refresh
            </span>
            <p className='text'>Reload Chart</p>
      </div>
  )
}

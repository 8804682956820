import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import { UserProvider } from './Context/UserContext'
import { ChartTouchProvider } from './Context/ChartScrollContext';
import { ProgressProvider } from './Context/ProgressContext';
import { DeviceProvider } from './Context/DeviceContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
  <div style={{ backgroundColor: "white" }}>

    <DeviceProvider>
      <ChartTouchProvider>
        <UserProvider>
          <ProgressProvider>
            <App />
          </ProgressProvider>
        </UserProvider>
      </ChartTouchProvider>
    </DeviceProvider>



  </div>


  //</React.StrictMode> fucks up the animations
);


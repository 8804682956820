import React, { useEffect, useRef, useState } from 'react'
import PerformanceBlockData from '../components/Goals/PerformanceBlockData'
import axios from 'axios'
import apiConfig from '../apiConfig'
import FixedMenu from '../components/FixedMenu/FixedMenu'
import CryptoLoadingAnimation from '../components/LoadingAnimation/CryptoLoadingAnimation'
import { motion } from "framer-motion"


export default function AdlerAssets() {
    const baseURL = apiConfig.baseUrl

    const [adlerNomval, setAdlerNomval] = useState()
    const [absPerformace, setAbsPerformance] = useState()
    const [relativePerf, setRelativePerf] = useState()
    const [adlerKapital, setAdlerKapital] = useState()
    const [adlerRewards, setAdlerRewards] = useState()
    const [animation, setAnimation] = useState(true)

    const ethPrice = useRef()

    function toggleAnimation() {
        setAnimation(!animation)
    }

    async function fetchAdlerNomval() {
        const response = await axios.get(`${baseURL}/api/crypto/adlerNomval`)
        setAdlerNomval(response.data.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }))
        return response.data
    }


    const ethAdresses = [
        { name: "Bueno", address: "0x50cBc94CDcd883AF15163A39a98E7d06daDE4C56" },
        { name: "Tutti", address: "0x569eaa46d294874A1211d5c0Ac0FA59e65543FC2" },
        { name: "Niggi", address: "0xe792dc909383AF355a9990C7cD230D92B83d0FE1" },
        { name: "Ralle", address: "0xB95BD08D0759ed5eE40E221C2f338f8A11A56238" },
    ]

    async function fetchStakingRewards(adress, name, setUser) {
        adress = null;
        switch (name) {
            case "Bueno": { adress = ethAdresses[0].address; break; }
            case "Tutti": { adress = ethAdresses[1].address; break; }
            case "Niggi": { adress = ethAdresses[2].address; break; }
            case "Ralle": { adress = ethAdresses[3].address; break; }
        }

        let response = 0;
        let rewardsEth = 0;
        if (adress) {
            const response = await axios.get(`https://reward-history-backend.lido.fi/?address=${adress}&currency=eur&limit=1`, { timeout: 2000 }).catch(error => { console.log("Lido API Timeout"); return 0 })
            const data = response?.data
            ethPrice.current = response?.data?.stETHCurrencyPrice?.eur
            const rewardsWei = data?.totals.ethRewards
            rewardsEth = rewardsWei * 10e-19

        }

        // if (setUser) {
        //   setUserStethReward(rewardsEth)
        // }

        if (isNaN(rewardsEth)) rewardsEth = 0

        return rewardsEth;

    }

    async function fetchAdlerRewardsEuro() {
        let adlerStethRewards = 0
        adlerStethRewards = await fetchStakingRewards(null, "Bueno", false);
        adlerStethRewards += await fetchStakingRewards(null, "Tutti", false);
        adlerStethRewards += await fetchStakingRewards(null, "Niggi", false);

        if(adlerStethRewards){
            setAdlerRewards((adlerStethRewards * ethPrice.current).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }))
        }else{
            setAdlerRewards("0.00€")
        }
       
        return adlerStethRewards * ethPrice.current
    }


    async function setAllData() {
        const nomval = await fetchAdlerNomval()
        let currentVal = await axios.get((`${baseURL}/api/crypto/adlerKapital`))
        const rewardsEuro = await fetchAdlerRewardsEuro()

        if (rewardsEuro) { currentVal = currentVal.data + rewardsEuro } else { currentVal = currentVal.data }

        setAdlerKapital(currentVal.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }))
        const rel = (currentVal / nomval - 1) * 100
        setAbsPerformance((currentVal - nomval).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }))
        setRelativePerf(rel.toFixed(2))

        toggleAnimation()
    }

    useEffect(() => {
        setAllData()
    }, [])

    const color = "green"



    return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
            {!animation ? (
                <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                >
                    <div>
                        <div className='data-block-container'>
                            <div className='performancedata-container'>
                                <div className='data-container'>
                                    <PerformanceBlockData headline={"Relative"} content={`${relativePerf}%`} contentColor={color} bottomText={"Since Start"}></PerformanceBlockData>

                                </div>

                                <div className='data-container'>
                                    <PerformanceBlockData headline={"Absolute"} content={absPerformace} contentColor={color} bottomText={"Since Start"}></PerformanceBlockData>
                                </div>
                            </div>

                            <div className='performancedata-container'>
                                <div className='data-container'>
                                    <PerformanceBlockData headline={"Nominal Value"} content={adlerNomval} contentColor={"neutral"} bottomText={"Since Start"}></PerformanceBlockData>
                                </div>

                                <div className='data-container'>
                                    <PerformanceBlockData headline={"Staking Rewards"} content={adlerRewards} contentColor={"green"} bottomText={"Since Start"}></PerformanceBlockData>
                                </div>
                            </div>

                        </div>

                        <div className='performancedata-container'>
                            <div className='data-container ' style={{ width: "100%", height: "70px", display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "-4px" }}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                                    <h1 className='missing-cash-text'>{adlerKapital}</h1>
                                    <p className='missing-cash-small-text'>Adler Assets</p>
                                </div>
                            </div>
                        </div>

                        <FixedMenu></FixedMenu>


                    </div>
                </motion.div>
            ) : (<CryptoLoadingAnimation/>)}
        </motion.div>
    )
}

import React, { useState } from 'react';
import SpinnerLoader from '../Loader/SpinnerLoader'

export default function PerformanceBlockData({ headline, content, contentColor, bottomText }) {

    

    const getContent = () => {
        return <div>
            <h1 className={`datablock-content ${contentColor}`}>{content}</h1>
            {bottomText ? <p className='since-start-text'>{bottomText}</p> : (<p></p>)}
        </div>
    }



    return (
        <div className='datablock-container'>
            <div className='data-headline-container'>
                <p className='datablock-headline'>{headline}</p>
            </div>

            <div className='datablock-content-container'>
                {getContent ? getContent():<SpinnerLoader></SpinnerLoader>}
            </div>

        </div>
    )
}

import React from 'react'
import "./GenericModalContentButton.css"

export default function GenericModalContentButton({buttonText, iconName, handleFunction, ticker, customWidth, customBackground, iconColor}) {
  return (
    <div onClick={()=>{handleFunction(ticker)}} className='scan-modal-container' style={{width:customWidth, backgroundColor:customBackground}}>
            <span className="material-symbols-outlined" style={{color:iconColor}}>
                {iconName}
            </span>
            <p className='scan-modal-text'>{buttonText}</p>


      </div>
  )
}

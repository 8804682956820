import React, { useState, useEffect, useRef } from 'react'
import "./AdlerLoginFormV1.css"
import AdlerLogo from "../../assets/Login/AdlerLogoV2.svg"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../../Context/UserContext';
import ApiConfig from '../../apiConfig';

export default function AdlerLoginFormV1({showLogin}) {
    const usernameRef = useRef(null);
    const buttonRef = useRef(null); 

    const baseURL = ApiConfig.baseUrl

    const [username, setUsername] = useState();
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const { setUserData } = useUser();
    const [savedUsers, setSavedUsers]= useState([])
    const [saveUserData, setSaveUserdata]= useState(false)

    const updatedUsers = [...savedUsers, { username, password }];

    let data = JSON.stringify({
        "username": username,
        "password": password
    });

    let configLogin = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseURL}/api/user/login`,
        headers: {
        'Content-Type': 'application/json'
        },
        data: data
    };

    let configCreate = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseURL}/api/user/create`,
        headers: {
        'Content-Type': 'application/json'
        },
        data: data
    };

    const handleEmailChange = (event) => {
        setUsername(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    function Sleep(milliseconds) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    function toggleSaveUserdata(){
        setSaveUserdata(!saveUserData)
    }

    const handleDelete = (username) => {
        // Filter out the user to be deleted
        const updatedUsers = savedUsers.filter(user => user.username !== username);
    
        // Update the state
        setSavedUsers(updatedUsers);
    
        // Update the localStorage
        localStorage.setItem('savedUsers', JSON.stringify(updatedUsers));
      };


    const checkLogin = async () => {
        showLogin(false)
        
        try {
        const response = await axios.request(configLogin)
        if (response.data === "Successfully logged in") {
            if(saveUserData) localStorage.setItem('savedUsers', JSON.stringify(updatedUsers));
            console.log(JSON.stringify(response.data));
            setUserData({ username, password });
            navigate("/home");
            showLogin(true)
        } else{
            showLogin(true)
        }

        }
        catch (error) {
        showLogin(true)
        console.log(error);
        window.alert("Fuck you");
        }
    }


    const createUser = async () => {
        try {
        const response = await axios.request(configCreate);
        console.log(JSON.stringify(response.data));
        }
        catch (error) {
        console.log(error);
        window.alert("Wrong, Idiot!");
        }
    }

    useEffect(() => {
        const savedUsersStr = localStorage.getItem("savedUsers");
        let users = [];
    
        if (savedUsersStr) {
          try {
            users = JSON.parse(savedUsersStr);
          } catch (error) {
            console.error("Error parsing saved users:", error);
            users = [];
          }
        }
    
        // Clear localStorage and sessionStorage
        localStorage.clear();
        sessionStorage.clear();
    
        // Remove duplicates based on the username
        const uniqueUsers = Array.from(
          new Map(users.map(user => [user.username, user])).values()
        );
    
        // Save unique users back to localStorage
        if (uniqueUsers.length > 0) {
          localStorage.setItem("savedUsers", JSON.stringify(uniqueUsers));
          setSavedUsers(uniqueUsers);
        }
      }, [])

  return (
    <div className='login-top-container'>
        <img style={{marginTop:"5px", width:"200px"}} src={AdlerLogo}></img>
            
        <div className='login-input-containerV1'>
            <p className='input-text'>Username</p>
            <input onChange={handleEmailChange} className='login-inputV1'></input>

            <p className='input-text'>Password</p>
            <input onChange={handlePasswordChange} type='password' className='login-inputV1'></input>

            <div className="remember-account-button-container">
                <input onClick={toggleSaveUserdata} type="checkbox" className="form-check-input" id="exampleCheck1" />
                <label style={{marginLeft:"8px"}} className="form-check-label" htmlFor="exampleCheck1">Remember Account</label>
            </div>
        </div>

        <div className='login-divider-container'>
            <div className='dividerL'></div>
            <p className='divider-text'>or</p>
            <div className='dividerR'></div>
        </div>

        <h1 className='saved-accounts-heading'>Saved Accounts</h1>

        <div style={{display:"flex", flexDirection:"column", width:"100%", marginTop:"20px"}}>
            {savedUsers?.map((user ,i)=>{
                return <div onClick={()=>{setUserData(user);navigate("/home");}} className='saved-account-containerV1' key={i}>
                <div className='saved-accountsL'>
                    <p className='savedAccountDescText'>Name</p>
                    <p className='savedAccountMainText'>{user.username}</p>
                </div>

                <div className='saved-accountsM'>
                    <p className='savedAccountDescText'>Password</p>
                    <p className='savedAccountMainText large'>{"•••••"}</p>
                </div>

                <div className='saved-accountsR'>

                    <span  className="material-symbols-outlined logowhite">
                        task_alt
                    </span>

                    <span onClick={(e) => {e.stopPropagation(); handleDelete(user.username);}} className="material-symbols-outlined logowhite">
                        delete
                    </span>
                </div>
            </div>
            })}
        </div>

        <button onClick={checkLogin} className='loginButton'>Login</button>
        
    </div>
  )
}

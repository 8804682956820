import React, { useEffect, useState } from 'react'
import axios from 'axios'
import apiConfig from '../../../apiConfig'
import "./AssetContainerWeb.css"
import down from "../../../assets/Arrows/triangle-down.png"
import up from "../../../assets/Arrows/triangle-up.png"
import { color } from 'framer-motion'

export default function AssetContainerWeb({index, assetData}) {
    const baseURL = apiConfig.baseUrl
    const [tickerData, setTickerData] = useState({})
    const ticker= assetData.assetName
    const amount= assetData.amountOwned
    const avgBuyIn= assetData.buyIn
    const value= assetData.assetValue
    const performance= assetData.relativePerformance
    


    async function getTickerData(ticker) {
        const response = await axios.get(`${baseURL}/api/lib/entry/${ticker.toLowerCase()}`)
        setTickerData(response.data)
        return response.data
    }

    
    useEffect(()=>{
        getTickerData(ticker)
    },[ticker])

    
    /**
     * Format a float number to a given precision, and remove trailing zeros.
     * @param {number} num - The number to format.
     * @param {number} precision - The number of decimal places to round to.
     * @returns {string} The formatted number as a string.
     */
    function formatFloat(num, precision) {
        let fixedNum = num.toFixed(precision);
        let trimmedNum = fixedNum.replace(/(\.\d*?[1-9])0+|\.0+$/, '$1');
        return trimmedNum;
    }

    
    /**
     * Uppercase the first character of a string, and every character that comes after a hyphen.
     * @param {string} str - The string to modify.
     * @returns {string} The modified string.
     */
    function formatName(str) {
        return str.replace(/(^|-)(.)/g, function(match, p1, p2) {
            return p1 + p2.toUpperCase();
        });
    }

  return (
    <div className='webAssetContainer'>

        {index === 0 ? (
            <div className='webAssetHeader'>
                <p style={{marginRight:"12vw"}} className='webAssetTitle'>Asset</p>
                <p className='webAssetTitle'>Balance</p>
                <p className='webAssetTitle'>Avg. Buy In</p>
                <p className='webAssetTitle'>Profit/ Loss</p>
                <p className='webAssetTitle'>Value</p>
            </div>
        ) : null}

        <div className='webAssetEntry'>
            

            <div className='webAssetName' style={{display:"flex", flexDirection:"row", marginRight:"12vw", minWidth:"50px"}}>
                <img className='webLogo' src={tickerData?.logo}></img>
                <p className='webAssetName'>{tickerData?.name && formatName(tickerData?.name)}</p>
                <p style={{marginLeft:"5px", fontSize:"10px", marginTop:"4px"}} className='webAssetTicker'>{ticker.toUpperCase()}</p>
            </div>
            

            <p className='webAssetAmount'>{formatFloat(parseFloat(amount), 4)}</p>

            <p className='webAssetAvgBuyIn'>{avgBuyIn.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</p>

            <div className='webAssetPerformance' style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                <img className='webArrow' src={performance>0 ? up : down}></img>
                <p className='webAssetPerformance' style={performance>0 ? {color:"#2dd649"} : {color:"#D80A0E"}} >{performance + "%"} </p>
            </div>

            <p className='webAssetValue'>{value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</p>
        </div>


    </div>
  )
}

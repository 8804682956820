import './App.css';
import "./css/DarkTheme.css"
import React, { Component, Fragment } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Transactions from './pages/Transactions';
import { ThemeProvider } from './Context/ThemeContext';
import TestPage from './pages/TestPage';
import AnalyticsModern from './pages/AnalyticsModern';
import Goals from './pages/Goals';
import Home1 from './pages/Home1';
import AdlerAssets from './pages/AdlerAssets';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useDevice } from './Context/DeviceContext';
import HomeWebVersion from './components/Home/WebVersion/HomeWebVersion';


function App() {

  const queryClient = new QueryClient()
  const {isMobile}= useDevice()

  return (
    <div style={{ backgroundColor: "#000000" }}>

      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <BrowserRouter>
            <Routes>
              <Route index element={<Login/>}></Route>

              <Route path='/home' element={<Home1></Home1>}></Route>

              <Route path='/transactions' element={<Transactions></Transactions>}></Route>

              <Route path='/analytics' element={<AnalyticsModern />}></Route>

              {/* <Route path='/goals' element={<Goals />}></Route> */}

              <Route path='/adlerPerformance' element={<AdlerAssets></AdlerAssets>}></Route>

              <Route path='/test' element={isMobile ? <TestPage></TestPage>: <HomeWebVersion></HomeWebVersion>}></Route>
            </Routes>
          </BrowserRouter>


        </ThemeProvider>
      </QueryClientProvider>

    </div>
  );
}

export default App;

import React, { useEffect, useRef, useState } from "react"
import { Button } from "react-bootstrap"
import './HomeOptionButton.css'
import AddCurrency from "./AddCurrency"
import CryptoAddButtonV2 from "../../Buttons/CryptoAddButtonV2"
import GenericModernModal from "../../Modal/GenericModernModal"
import LogoutButton from "../../Buttons/LogoutButton"
import { useNavigate } from 'react-router-dom';
import ReloadT1Button from "../../Buttons/ReloadT1Button"
import GenericModalContentButton from "../../Buttons/GenericModalContentButton"
import { useUser } from '../../../Context/UserContext';
import apiConfig from "../../../apiConfig"
import axios from "axios"

export default function HomeOptionsButton({toggleScrollInParent, homeReloadFunction, popUpFunction}) {
    const [showModal, setShowmodal]= useState(false)
    const [content, setContent]= useState()
    const navigate= useNavigate()
    const btcInput= useRef()
    const ethInput= useRef()
    const solInput= useRef()

    const baseURL= apiConfig.baseUrl

    const { userData: user } = useUser();
    let userData = JSON.stringify({
        "username": user.username,
        "password": user.password
    });

    const btcWalletSyncConfig= ()=>{
        return {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/transactions/syncWallet?address=${btcInput.current}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: userData
        }
    }

    const ethWalletSyncConfig= ()=>{
        return {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/transactions/syncEthWallet?address=${ethInput.current}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: userData
        }
    }


    function toggleModal(){
        setShowmodal(!showModal)
        if(!showModal) resetContent()
    }

    function handleAddCurrency(){
        setContent(<div>
            <div style={{marginTop:"50px"}}></div>
            <AddCurrency></AddCurrency>
        </div>)
    }

    function handleLogout(){
        setContent(
        <div style={{display:"flex", flexDirection:"column"}}>
            <h1 style={{marginTop:"30px", color:"white", marginLeft:"20px"}}>Sure?</h1>

            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-around", marginTop:"18px"}}>
                <p onClick={()=>toggleModal()} style={{color: "white", fontSize:"16pt", backgroundColor:"rgb(63, 63, 63)", borderRadius:"25px", width:"40%", display:"flex", justifyContent:"center", padding:"6px" }}>Cancel</p>
                <p onClick={()=>{navigate("/")}} style={{color: "white", fontSize:"16pt", backgroundColor:"red", borderRadius:"25px", width:"40%", display:"flex", justifyContent:"center", padding:"6px" }}>Continue</p>

            </div>

        </div>)
    }

    function handleReload(){
        setContent(
            <div style={{display:"flex", flexDirection:"column"}}>
                <h1 style={{marginTop:"30px", color:"white", marginLeft:"20px"}}>Sure?</h1>
    
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-around", marginTop:"18px"}}>
                    <p onClick={()=>toggleModal()} style={{color: "white", fontSize:"16pt", backgroundColor:"rgb(63, 63, 63)", borderRadius:"25px", width:"40%", display:"flex", justifyContent:"center", padding:"6px" }}>Cancel</p>
                    <p onClick={()=>{homeReloadFunction(true); toggleModal()}} style={{color: "white", fontSize:"16pt", backgroundColor:"green", borderRadius:"25px", width:"40%", display:"flex", justifyContent:"center", padding:"6px" }}>Continue</p>
    
                </div>
    
            </div>)
    }

    function handleConnectWallet(){

        async function processWalletConnect(ticker){
            console.log(ticker)
            switch(ticker){
                case "btc": {
                    const response=await axios.request(btcWalletSyncConfig(btcInput.current))
                    popUpFunction(response.data)
                    handleConnectWallet()
                    break;
                }

                case "eth": {
                    const response=await axios.request(ethWalletSyncConfig(ethInput.current))
                    popUpFunction(response.data)
                    handleConnectWallet()
                    break;
                }
            }
        }

        setContent(
            <div>
                <div className="wallet-input-container mb-3" style={{marginTop:"40px"}}>
                    <input onChange={(e)=>{btcInput.current=e.target.value}} type="text" className="wallet-input" placeholder="BTC Address..." aria-label="BTC Address"/>
                    <button onClick={()=>processWalletConnect("btc")} className="btn walletAddButton" type="button" id="button-addon1">Add</button>
                </div>

                <div className="wallet-input-container mb-3" style={{marginTop:"40px"}}>
                    <input onChange={(e)=>{ethInput.current=e.target.value}} type="text" className="wallet-input" placeholder="ETH Address..." aria-label="ETH Address"/>
                    <button onClick={()=>processWalletConnect("eth")} className="btn walletAddButton" type="button" id="button-addon2">Add</button>
                </div>

                <div className="wallet-input-container mb-3" style={{marginTop:"40px"}}>
                    <input onChange={(e)=>{solInput.current=e.target.value}} type="text" className="wallet-input" placeholder="SOL Address..." aria-label="SOL Address"/>
                    <button className="btn walletAddButton" type="button" id="button-addon3">Add</button>
                </div>

                
            </div>
            
        )
    }

    function resetContent(){
        setContent(<div>
            <h1 style={{ marginTop: "30px"}} className="title">Options</h1>
            <CryptoAddButtonV2 handleFunction={handleAddCurrency}></CryptoAddButtonV2>
            <ReloadT1Button handleFunction={handleReload}></ReloadT1Button>
            <GenericModalContentButton buttonText={"Connect Wallets"} iconName={"cloud_upload"} handleFunction={handleConnectWallet}></GenericModalContentButton>
            <LogoutButton handleFunction={handleLogout}></LogoutButton>
            

            <div style={{height:"20px"}}></div>
        </div>)
    }



    useEffect(()=>{
        resetContent()
    },[])

    return (
    <div>
        <button onClick={()=>toggleModal()} className="optionsButton">
            <div className="optionButtonContent">
            <span className="material-symbols-outlined">
                more_horiz
            </span>
            <p className="optionsButtonText">Options</p>
            </div>
        </button>

        {showModal ? (<GenericModernModal closingThreshold={50} toggleVisibleStateInParent={toggleModal} content={content} modalVisible={showModal} toggleScrollFunction={toggleScrollInParent} ></GenericModernModal>):(<div></div>)}
    </div>)
}
import React from 'react'
import GenericModalContentButton from '../../Buttons/GenericModalContentButton'

export default function ScanModalContent({handleFunction}) {
  return (
    <div style={{marginTop:"25px"}}>
        <h1 style={{color:"white", fontSize:"17pt", marginTop:"15px", marginBottom:"18px" }}>Options</h1>
        <GenericModalContentButton  handleFunction={handleFunction} ticker={"Bitcoin"} buttonText={"Synchronize BTC"} iconName={"sync"}></GenericModalContentButton>
        <GenericModalContentButton handleFunction={handleFunction} ticker={"Ethereum"} buttonText={"Synchronize ETH"} iconName={"sync"}></GenericModalContentButton>
        <GenericModalContentButton handleFunction={handleFunction} ticker={"Solana"} buttonText={"Synchronize Solana (maybe)"} iconName={"sync"}></GenericModalContentButton>
        <div style={{height:"20px"}}></div>
    </div>
  )
}

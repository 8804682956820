import React from 'react'
import LineChartV3 from '../Chart/LineChartV3'
import "./HomeWebVersion.css"

export default function HomeWebVersion(props) {


  return (
    <div>
        <div className='outer-grid'>
          <div style={{height:"48vh", backgroundColor:"#0d0d0e", borderRadius:"25px", overflowY:"hidden"}}>
            
          </div>


          <div style={{height:"48vh", backgroundColor:"#0d0d0e", borderRadius:"25px"}}>Der Adler</div> 
          <div style={{height:"48vh", backgroundColor:"#0d0d0e", borderRadius:"25px"}}>Der Adler</div> 
          <div style={{height:"48vh", backgroundColor:"#0d0d0e", borderRadius:"25px"}}>Der Adler</div> 

        </div>
    </div>
  )
}

import React, { useEffect, useRef, useState } from 'react';
import './AddCurrency.css'; // Import the CSS file
import axios from 'axios';
import apiConfig from '../../../apiConfig';


export default function AddCurrency() {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isOwned, setIsOwned] = useState()
  const baseURL = apiConfig.baseUrl

  let responseData = []

  let insertConfig = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseURL}/api/lib/insert`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { ticker: searchResults[0]?.symbol, name: searchResults[0]?.name, logo: searchResults[0]?.image }
  };

  async function insertCurrentResult() {
    if (searchResults[0]) {
      const response = await axios.request(insertConfig).catch((err) => { });
    }
  }


  const handleSearch = async () => {
    responseData = []
    const response = await axios.get(`https://api.coingecko.com/api/v3/coins/${searchQuery.toLowerCase()}?id?localization=false&community_data=false&developer_data=false&sparkline=false&ticker=false'`).catch((err) => { console.log("Not Found!") })
    if (response) {
      responseData.push({
        id: response.data.id,
        name: response.data.id,
        image: response.data.image.large,
        symbol: response.data.symbol,
        owned: checkIfOwned(response.data.symbol)
      })
    } else {
      responseData.push({
        id: 0,
        name: "Not Found!",
        image: null,
        symbol: "",
      })
    }
    setSearchResults(responseData)
  };


  function checkIfOwned(ticker) {
    let ownedTickers = JSON.parse(localStorage.getItem("ownedTickers") || "[]");
    return ownedTickers.data.some(t => t.toLowerCase() === ticker);
  }

  function buttonHandler() {
    if (searchResults[0].name !== "Not Found!") {
      const symbol = searchResults[0].symbol;
      if (!checkIfOwned(symbol)) {
        insertCurrentResult()
        let ownedTickers = JSON.parse(localStorage.getItem("ownedTickers") || "[]");
        ownedTickers.push(symbol);
        console.log(ownedTickers)
        localStorage.setItem("ownedTickers", JSON.stringify(ownedTickers));
      } else {
        console.log(`Ticker ${symbol} already exists in ownedTickers.`);
      }
    }
  }

  function deleteButtonHandler() {
    let ownedTickers = JSON.parse(localStorage.getItem("ownedTickers") || "[]");

    // Filter out the ticker to delete
    const updatedTickers = ownedTickers.filter(ticker => ticker !== searchResults[0].symbol);
    console.log(updatedTickers)

    // Update localStorage with the updated tickers array
    localStorage.setItem("ownedTickers", JSON.stringify(updatedTickers));


    // Optionally, update the state with the updated tickers array
    setSearchResults(updatedTickers);
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
      event.target.blur();
    }
  };

  const content = (<div className='main-container'>
    <h5 className='addCurrencyText'>Add Currency</h5>

    <div className='input-container'>
      <input
        type="text"
        placeholder="Search currency..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyDown={handleKeyPress}
        className="currencyAddInput"
      />

      <div className='searchButtonContainer'>
        <button onClick={handleSearch} className="currencyAddButton">
          <span style={{ marginRight: "8px" }} className="material-symbols-outlined">search</span>
        </button>
      </div>
    </div>

    <ul style={{ padding: "5px" }} className="currencySearchResultList">
      {searchResults.map((currency) => (
        <li key={currency.id} className="currencySearchResultItem">
          <span>{currency.name} ({currency.symbol})</span>
          <img style={{ height: "30px", width: "30px" }} src={currency.image} alt={`${currency.name} logo`} />
          {currency.owned ? (
            <button className="currencyAddToBalanceButton" onClick={() => { deleteButtonHandler(); handleSearch() }}>
              <span className="material-symbols-outlined whiteFont">check</span>
            </button>
          ) : (
            <button className="currencyAddToBalanceButton" onClick={() => { buttonHandler(); handleSearch() }}>
              <span className="material-symbols-outlined whiteFont">download</span>
            </button>
          )}
        </li>
      ))}
    </ul>
    <div style={{ marginBottom: "50%" }}></div>
  </div>
  );


  return (content)

}

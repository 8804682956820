import React, { useEffect, useState } from 'react'
import LineChartReV2 from '../Chart/LineChartReV2'
import up from "../../../assets/Arrows/triangle-up.png"
import down from "../../../assets/Arrows/triangle-down.png"

export default function AssetModalContent({ assetName, totalValue, performance, totalReturn, amount, buyIn, allocation }) {

    const[positive, setPositive]= useState()

    useEffect(()=>{
        if(totalReturn>0){
            setPositive(true)
        }else{
            setPositive(false) 
        }

    },[])

    return (
        <div>
            <LineChartReV2 chartHeight={250} ticker={assetName}></LineChartReV2>
            <div className='modalMainContainer'>
                <h4 className='mainTitle'>{assetName}</h4>

                <div className='valueGrid'>

                    <div className='valueTitleLayout'>
                        <p className='secondaryTitleSmall'>Position</p>
                        <p className='valueTitle'>{totalValue.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</p>
                    </div>

                    <div className='valueTitleLayout'>
                        <p className='secondaryTitleSmall'>Amount</p>
                        <p className='valueTitle'>{amount.toFixed(3)}</p>
                    </div>

                    <div className='valueTitleLayout'>
                        <p className='secondaryTitleSmall'>Performance</p>
                        <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                            <img className='upDownPic'  src={positive ? up : down}></img>
                            <p className={`valueTitle ${positive? "modalGreen": "modalRed"}`}>{performance.toFixed(2) + " %"}</p>
                        </div>

                    </div>

                    <div className='valueTitleLayout'>
                        <p className='secondaryTitleSmall'>Buy In</p>
                        <p className='valueTitle'>{buyIn.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</p>
                    </div>

                    <div className='valueTitleLayout'>
                        <p className='secondaryTitleSmall'>% Portf.</p>
                        <p className='valueTitle'>{allocation.toFixed(2) + " %"}</p>
                    </div>

                    <div className='valueTitleLayout'>
                        <p className='secondaryTitleSmall'>Return</p>
                        <p className={`valueTitle ${positive? "modalGreen": "modalRed"}`}>{totalReturn.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</p>
                    </div>

                </div>

            </div>
        </div>
    )
}

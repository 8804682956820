import React, { useState, useContext, useEffect, useRef } from 'react'
import "./TransactionActions.css"
import add from "../../assets/ActionButtons/add.svg"
import scan from "../../assets/ActionButtons/scan.svg"
import search from "../../assets/ActionButtons/search.svg"
import TransactionInputForm from '../Transactions/TransactionInputForm'
import GenericModernModal from '../Modal/GenericModernModal'
import { MyContext } from '../../Context/MyContext'
import TransactionModalContent from './TransactionModalContent'
import apiConfig from '../../apiConfig'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../Context/UserContext';
import ScanModalContent from '../Home/Modal/ScanModalContent'
import { useProgress } from '../../Context/ProgressContext'
import { Progress } from 'rsuite'
import LoadingAnimationButton from '../Buttons/LoadingAnimationButton'
import { AnimatePresence, motion } from 'framer-motion'

export default function TransactionActions({toggleScrollFunction, refreshHandler, popUpFunction}) {
    const [showModal, setShowModal]= useState(false)
    const showModalRef= useRef(false)
    const [content, setContent]= useState()
    const { checkedTransactions, handleCheckboxChange, resetCheckBoxArray } = useContext(MyContext);
    const baseURL= apiConfig.baseUrl
    const navigate= useNavigate()
    const [forceClose, setForceClose]= useState()
    const [showLoadingButton, setShowLoadingButton]= useState(false)
    const [progressPercentage, setProgressPercentage]= useState(0)

    const progressData= useProgress()
    const { userData: user } = useUser();
    let userData = JSON.stringify({
        "username": user.username,
        "password": user.password
    });

    const btcScanConfig= ()=>{
        return {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/transactions/importBtcTransactions?sessionId=${progressData.sessionKey}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: userData
        }
    }

    const ethScanConfig= ()=>{
        return {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/transactions/importERC20Transactions?sessionId=${progressData.sessionKey}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: userData
        }
    }

    function toggleModal(action){
        if(!showModal) buildContent(action)
        if(showModal) resetCheckBoxArray()
        setShowModal(!showModal)
    }

    function toggleForceCloseModal(){
        setForceClose(true)
        setTimeout(() => {
            setForceClose(false)
        }, 100);
    }

    function Sleep(milliseconds) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }


    function handleDelete(){

        async function processDelete(){
            try{
                await axios.delete(`${baseURL}/api/transactions/delete/${checkedTransactions[0].id}`);
                resetCheckBoxArray()
                refreshHandler()
                sessionStorage.setItem("didMount", false)
            }catch(error){
                console.log("error deleting transaction")
            }
        }

        setContent(
        <div style={{display:"flex", flexDirection:"column"}}>
            <h1 style={{marginTop:"30px", color:"white", marginLeft:"20px"}}>Sure?</h1>

            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-around", marginTop:"18px"}}>
                <p onClick={()=>{toggleModal("transaction")}} style={{color: "white", fontSize:"16pt", backgroundColor:"rgb(63, 63, 63)", borderRadius:"25px", width:"40%", display:"flex", justifyContent:"center", padding:"6px" }}>Cancel</p>
                <p onClick={()=>{processDelete(); toggleForceCloseModal()}} style={{color: "white", fontSize:"16pt", backgroundColor:"red", borderRadius:"25px", width:"40%", display:"flex", justifyContent:"center", padding:"6px" }}>Continue</p>

            </div>

        </div>)
    }

    function handleScan(ticker){
        
        async function processScan(){
            setShowLoadingButton(true)
            switch(ticker){
                case "Bitcoin": {
                    const result=await axios.request(btcScanConfig())
                    if(result.data==="Added 0 entries to the DB"){
                        /* popUpFunction(result.data) */
                        await Sleep(2000)
                        setShowLoadingButton(false)
                        break;
                    }else{
                        /* popUpFunction(result.data) */
                        refreshHandler()
                        sessionStorage.setItem("didMount", false)
                        await Sleep(2000)
                        setShowLoadingButton(false)
                        break;
                    }
                }
                case "Ethereum": {
                    const result=await axios.request(ethScanConfig())
                    if(result.data==="Added 0 entries to the DB"){
                        await Sleep(2000)
                        setShowLoadingButton(false)
                        /* popUpFunction(result.data) */
                        break;
                    }else{
                        /* popUpFunction(result.data) */
                        refreshHandler()
                        sessionStorage.setItem("didMount", false)
                        await Sleep(2000)
                        setShowLoadingButton(false)
                        break;
                    }
                    
                }
                case "Solana": {}
            }
        }

        setContent(
            <div style={{display:"flex", flexDirection:"column"}}>
                <h1 style={{marginTop:"30px", color:"white", marginLeft:"20px"}}>{"Sync "+ticker+"?"}</h1>
    
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-around", marginTop:"18px"}}>
                    <p onClick={()=>{buildContent("scan")}} style={{color: "white", fontSize:"16pt", backgroundColor:"rgb(63, 63, 63)", borderRadius:"25px", width:"40%", display:"flex", justifyContent:"center", padding:"6px" }}>Cancel</p>
                    <p onClick={()=>{processScan();toggleForceCloseModal()}} style={{color: "white", fontSize:"16pt", backgroundColor:"green", borderRadius:"25px", width:"40%", display:"flex", justifyContent:"center", padding:"6px" }}>Continue</p>
    
                </div>
    
            </div>)
    }

    function buildContent(action){
        switch (action){
            case "add": {setContent(<TransactionInputForm refreshHandler={refreshHandler}></TransactionInputForm>); break;}
            case "transaction": {setContent(<TransactionModalContent refreshHandler={refreshHandler} popUpFunction={popUpFunction} handleDelete={handleDelete} toggleModal={toggleForceCloseModal} transaction={checkedTransactions[0]}></TransactionModalContent>); break;}
            case "scan": {setContent(<ScanModalContent handleFunction={handleScan}></ScanModalContent>); break;}
            
        }
    }

    useEffect(()=>{
        setProgressPercentage(progressData.progressData?.progress)
        if(progressData.progressData!=undefined) console.log(progressData?.progressData)
    },[progressData.progressData])


    useEffect(()=>{
        if(checkedTransactions.length>0) toggleModal("transaction")
    },[checkedTransactions])

    
    
  return (
    <>
        <div className='top-container'>
            <h1 className='action-header'>Actions</h1>
            <div className='actions-container'>
            
                <div className='actionButtonContainer'>
                    <div className='circle' onClick={()=>toggleModal("add")}>
                        <img className='action-icon' src={add}></img>
                    </div>
                    <p className='button-title'>Add</p>
                </div>

                <AnimatePresence mode="wait">
                    {showLoadingButton ? (
                        <motion.div
                            key="loading"
                            className='actionButtonContainer'
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <LoadingAnimationButton percentage={progressPercentage} />
                        </motion.div>
                    ) : (
                        <motion.div
                            key="scan"
                            className='actionButtonContainer'
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <div onClick={() => toggleModal("scan")} className='circle'>
                                <img className='action-icon' src={scan} alt="Scan" />
                            </div>
                            <p className='button-title'>Scan</p>
                        </motion.div>
                    )}
                </AnimatePresence>

                <div className='actionButtonContainer'>
                    <div className='circle'>
                        <img className='action-icon' src={search}></img>
                    </div>
                    <p className='button-title'>Search</p>
                </div>

                
            </div>
        </div>
    {showModal ? (<GenericModernModal closingThreshold={50} toggleVisibleStateInParent={toggleModal} content={content} modalVisible={showModal} toggleScrollFunction={toggleScrollFunction} forceClose={forceClose} ></GenericModernModal>):(<div></div>)}
    </>
  )
}

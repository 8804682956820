import React, { useEffect, useState } from 'react'
import LineChartV3 from '../components/Home/Chart/LineChartV3'
import FixedMenu from '../components/FixedMenu/FixedMenu'
import '../css/Home.css'


const Home1 = () => {

  return (
    <div>
      <LineChartV3></LineChartV3>
    </div>
  )
}

export default Home1

import React, { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios';
import "./TransactionInputForm.css"
import { useUser } from '../../Context/UserContext';
import { MyContext } from '../../Context/MyContext';
import SuccessPopup from '../Home/Loading/SuccessPopup';
import ApiConfig from '../../apiConfig';

export default function TransactionInputForm({ refreshHandler }) {

    const baseURL = ApiConfig.baseUrl
    const [selectedCrypto, setSelectedCrypto] = useState("BTC")
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedPrice, setPrice] = useState()
    const [selectedAmount, setAmount] = useState()
    const { userData } = useUser();
    const { checkedTransactions, handleCheckboxChange, resetCheckBoxArray } = useContext(MyContext);
    const selectedOption = useRef()
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [popupText, setPopupText] = useState('');
    const [ownedTickers, setOwnedTickers] = useState();


    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };



    async function getOwnedTicker() {
        let ownedTickers = JSON.parse(localStorage.getItem("ownedTickers") || "[]");
        if(ownedTickers.data.length > 0 ){
            setOwnedTickers(ownedTickers.data)
            return
        }

        try {
            const response = await axios.request(tickerApiConfig);
            const newData = response.data;

            ownedTickers = ownedTickers.concat(newData);
            ownedTickers = [...new Set(ownedTickers)]

            // Update localStorage and state
            localStorage.setItem("ownedTickers", JSON.stringify(ownedTickers));
            setOwnedTickers(ownedTickers);
        } catch (error) {
            console.error("Error fetching ticker data:", error);
        }
    }

    let createTransData = JSON.stringify({
        user: {
            username: userData.username,
            password: userData.password
        },
        ticker: selectedCrypto,
        price: selectedPrice,
        amount: selectedAmount,
        date: formatDateToCustomFormat(selectedDate)
    });

    let createSellTransData = JSON.stringify({
        user: {
            username: userData.username,
            password: userData.password
        },
        ticker: selectedCrypto,
        price: selectedPrice,
        amount: -selectedAmount,
        date: formatDateToCustomFormat(selectedDate)
    });


    let createConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseURL}/api/transactions/put`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: createTransData
    };

    let sellConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseURL}/api/transactions/put`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: createSellTransData
    };

    let tickerApiConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseURL}/api/transactions/ownedAssetTicker`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: { username: userData.username, password: userData.password }
    };


    const handleFunction = async () => {
        switch (selectedOption.current) {
            case "Add": {
                setPopupText('Bought!');
                await axios.request(createConfig)
                refreshHandler()
                setPopupVisible(true)
                sessionStorage.setItem("didMount", false)
                break;
            }

            case "Sell": {
                setPopupText("Sold!")

                await axios.request(sellConfig)
                refreshHandler()
                setPopupVisible(true)
                sessionStorage.setItem("didMount", false)
                break;
            }

            case "Delete": {
                setPopupText('Deleted!');
                for (let i = 0; i < checkedTransactions.length; i++) {
                    await axios.delete(`${baseURL}/api/transactions/delete/${checkedTransactions[i]}`);
                }
                resetCheckBoxArray()
                refreshHandler();
                setPopupVisible(true);
                sessionStorage.setItem("didMount", false)
                break;
            }
            default: { console.log("default") }
        }
    }



    function formatDateToCustomFormat(inputDate) {
        const parts = inputDate.split('-');
        if (parts.length === 3) {
            const year = parts[0];
            const month = parts[1];
            const day = parts[2];

            return `${day}.${month}.${year}`;
        } else {
            return 'Invalid Date';
        }
    }

    function handlePriceChange(e) {
        setPrice((e.target.value).replace(",", "."))
    }

    function vibrate() {
        // Check if the browser supports the Vibration API
        if ("vibrate" in navigator) {
            // Vibrate for 200 milliseconds
            navigator.vibrate(200);
        }
    }

    useEffect(() => {
        getOwnedTicker()
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setPopupVisible(false);
        }, 1500);

    }, [isPopupVisible]);



    const isLoading = !ownedTickers;



    return (
        <div className='main-container'>
            <div className='d-grid gap-2 d-md-block'>
                {isLoading ? (
                    <div className="btn-group" role="group" style={{ color: 'rgba(136, 132, 216, 0.8)', overflowX: 'auto', whiteSpace: 'nowrap' }}>
                        {/* Render placeholder divs here, adjust quantity as needed */}
                        <div className="placeholder"></div>
                    </div>
                ) : (
                    <div className="btn-group enable-scroll animated" role="group" aria-label="Basic radio toggle button group" style={{ color: 'rgba(136, 132, 216, 0.8)', overflowX: 'auto', whiteSpace: 'nowrap' }}>
                        {ownedTickers?.map((ticker, index) => (
                            <React.Fragment key={ticker}>
                                <input type="radio" className="btn-check" name="btnradio" id={`btnradio${index + 1}`} autoComplete="off" />
                                <label onClick={() => { setSelectedCrypto(ticker.toUpperCase()) }} className="btn btn-outline-primary1 " style={{borderColor:"rgb(66,66,66)"}} htmlFor={`btnradio${index + 1}`}>{ticker.toUpperCase()}</label>
                            </React.Fragment>
                        ))}
                    </div>
                )}
            </div>

            <div className='amountContainer '>
                <div className="input-group mb-3 ">
                    <span className="input-group-text fixed-width custom-bg">Amount:</span>
                    <input type="text" inputMode='decimal' className="form-control custom-bg" aria-label="Amount" onChange={(e) => { setAmount((e.target.value).replace(",", ".")) }} />
                    <span className="input-group-text fixed-width1 custom-bg">{selectedCrypto}</span>
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text fixed-width custom-bg">Price:</span>
                    <input type="text" inputMode='decimal' className="form-control custom-bg" aria-label="Price" onChange={(e) => handlePriceChange(e)} />
                    <span className="input-group-text fixed-width1 custom-bg">€</span>
                </div>
            </div>

            <div className="input-group mb-3">
                <span className="input-group-text fixed-width custom-bg">Date</span>
                <input className="form-control custom-bg allignleft" placeholder='test' type="date" value={selectedDate} onChange={handleDateChange} />
            </div>


            <div className='action-button-container'>
                <div className='action-button-layout'>
                    <button style={{ width: "100px" }} onClick={() => { selectedOption.current = "Add"; handleFunction() }} type="button" className="btn primary custom-button">Buy</button>
                    <button style={{ width: "100px" }} onClick={() => { selectedOption.current = "Sell"; handleFunction() }} type="button" className="btn primary custom-button">Sell</button>
                    <button style={{ width: "100px" }} onClick={() => { selectedOption.current = "Delete"; handleFunction() }} type="button" className="btn primary custom-button">Delete</button>
                </div>
            </div>

            <SuccessPopup isVisible={isPopupVisible} text={popupText} />

        </div >
    )
}

import React from 'react'
import './LogoutButton.css'
import { useNavigate } from 'react-router-dom';

export default function LogoutButton({handleFunction, customText, customLogoName, customWidth}) {

    const navigate = useNavigate();

  return (
    <div onClick={()=>{handleFunction()}} className='logoutButton' style={{width:customWidth}}>
            <span className="material-symbols-outlined fontRed">
                {customLogoName? customLogoName :"logout"}
            </span>
            <p className='text textRed'>{customText? customText :"Logout"}</p>
        </div>
  )
}

import React from 'react'
import loadingAnimation from "../../assets/Animations/loginLoadingAnimationFinal.json"
import Lottie from "lottie-react"

export default function CryptoLoadingAnimation() {
  return (
    <div style={{ marginTop: "170px" }}>
        <Lottie animationData={loadingAnimation}></Lottie>
    </div>
  )
}

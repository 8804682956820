import React, { useEffect, useRef, useState } from 'react'
import './AssetBoxModal.css'
import { useChartTouched } from '../../../Context/ChartScrollContext';
import { useDevice } from '../../../Context/DeviceContext';

export default function AssetBoxClickModal({ toggleVisibleStateInParent, modalVisible, toggleScrollFunction, content }) {
    const [showModal, setShowModal] = useState(modalVisible);
    const modalRef = useRef(null);
    const [startY, setStartY] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const [scrollY, setScrollY] = useState(0);
    const [currentlyActive, setCurrentlyActive] = useState("currentText")
    const [currentTextState, setCurrentText] = useState("Trend")
    const [closeModal, setCloseModal] = useState(false)
    const { touched } = useChartTouched()
    const {isMobile} = useDevice()
    const [mouseDown, setMouseDown]= useState(false)

    const backgroundOpacity = 0.67

    function toggleCloseModal() {
        setCloseModal(!closeModal)
        setTimeout(() => {
            setShowModal(false);
            if(toggleVisibleStateInParent) toggleVisibleStateInParent()
            if (toggleScrollFunction) toggleScrollFunction()
        }, 300);
        setTimeout(() => {
            setCloseModal(false)
        }, 300);
    }


    const handleTouchStart = (event) => {
        if(isMobile){
            setStartY(event.touches[0].clientY)
            setScrollY(modalRef.current.scrollTop)
        }else{
            setStartY(event.clientY)
            setScrollY(modalRef.current.scrollTop)
            setMouseDown(true)
        }
    };

    const handleTouchMove = (event) => {
        if(!isMobile && !mouseDown) return

        if(isMobile){
            const deltaY = event.touches[0].clientY - startY;
            const modalHeight = modalRef.current.clientHeight;
            let opacity;
    
            if (deltaY > 0 && modalRef.current.scrollTop === 0) {
                modalRef.current.style.transform = `translateY(${deltaY}px)`;
    
                opacity = Math.max(0, backgroundOpacity - Math.abs(deltaY * 2 / modalHeight));
                modalRef.current.style.backgroundColor = `rgba(1, 0, 0, ${opacity})`;
            }

        }else{
            if(mouseDown){
                const deltaY = event.clientY- startY
                const modalHeight = modalRef.current.clientHeight;
                let opacity

                if (deltaY > 0 && modalRef.current.scrollTop === 0) {
                    modalRef.current.style.transform = `translateY(${deltaY}px)`;
    
                    opacity = Math.max(0, backgroundOpacity - Math.abs(deltaY * 2 / modalHeight));
                    modalRef.current.style.backgroundColor = `rgba(1, 0, 0, ${opacity})`;
                }
            }
        }
       
    };

    const handleTouchEnd = (event) => {
        let deltaY=null

        if(isMobile){
            deltaY = event.changedTouches[0].clientY - startY;
        }else{
            deltaY = event.clientY - startY;
        }

        setMouseDown(false)
        
        const modalContent = modalRef.current.querySelector('.metric-modal-content');
        // const scrollPosition = modalContent.scrollTop;

        if (!modalContent.contains(event.target)) {
            toggleCloseModal()
        }

        
        if (deltaY > 170) {
            const animationStart = deltaY / modalRef.current.clientHeight * 100;
            modalRef.current.style.animation = `slideOut 0.3s ease forwards`;
            modalRef.current.style.animationDelay = `${-animationStart}ms`;
            modalRef.current.style.transform = `translateY(-${deltaY}px)`;
            setTimeout(() => {
                try{
                    modalRef.current.style.transition = ''; // Remove transition after animation
                    toggleCloseModal();
                }catch(err){
                    console.log("modalRef animation not reset")
                    toggleCloseModal();
                }
                
            }, 300);
        } else {
            // If modal doesn't close, return it to standard position
            modalRef.current.style.transition = 'transform 0.3s ease';
            modalRef.current.style.transform = 'none';
            setTimeout(() => {
                try{
                    modalRef.current.style.transition = ''; // Remove transition after animation
                }catch(err){
                    console.log("modalRef animation not reset")
                }
                
            }, 300);
        }

        modalRef.current.style.transform = '';
        modalRef.current.style.backgroundColor = `rgba(0, 0, 0, ${backgroundOpacity})`;
    };


    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>

            {showModal && (
                <div
                    className="metric-modal-background fullScreenBackground"
                    ref={modalRef}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}

                    onMouseDown={handleTouchStart}
                    onMouseMove={handleTouchMove}
                    onMouseUp={handleTouchEnd}
                >
                    <div className="closing-slider-container toBackground">
                        <div className='closing-slider'></div>
                    </div>

                    <div className={`metric-modal-content fullheight ${closeModal ? "close" : ""} `}>     {/* (contentScrollable) makes content scrollable */}

                        {content}

                    </div>
                </div>
            )}
        </div>

    )
}
